import React, { Component } from 'react';
import { Container, Card } from 'semantic-ui-react';
import { Title, UniversalLink } from '@plone/volto/components';
import ReactPlayer from 'react-player';

const ContentFolderView = (props) => {
  const { content } = props;

  return (
    <Container className="content-folder-wrapper">
      <article className="container">
        <h1 className="documentFirstHeading">{content.title}</h1>
        {content.description && (
          <p className="documentDescription">{content.description}</p>
        )}

        <section className="content-core">
          <Card.Group className="file-list">
            {content?.items?.map((item, index) => {
              return (
                <>
                  <Card href={item.url} className="file-card info" key={index}>
                    <Card.Content>
                      <Card.Header>{item.title}</Card.Header>
                      <Card.Meta>{item.description}</Card.Meta>
                    </Card.Content>
                    {item.image_field && (
                      <img
                        src={`${item['@id']}/@@images/image/mini`}
                        className="file-card-image"
                        alt={item.title}
                      />
                    )}
                  </Card>
                </>
              );
            })}
          </Card.Group>
        </section>
      </article>
    </Container>
  );
};

export default ContentFolderView;
