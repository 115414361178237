import React, { Component } from 'react';
import { Header, Card, List, Button, Segment } from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import { Icon, Popup } from 'semantic-ui-react';

const View = (props) => {
  const { data, id, isEditMode, intl, properties } = props;

  return (
    <div className="features-list">
      <Card.Group className="sub-level-list">
        {properties.list_of_feature &&
          properties.list_of_feature.items.map((sub_level, index) => {
            return (
              <Card key={index} className="item-card info">
                <div className="main-card">
                  <Card.Header>
                    <h3 className="title">{sub_level.sub}</h3>
                  </Card.Header>
                  {sub_level.features && (
                    <Card.Content>
                      {sub_level.description.data !== '<p></p>' ? (
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: sub_level.description.data,
                          }}
                        ></div>
                      ) : (
                        <></>
                      )}
                      <div className="features-list">
                        <List as="ul">
                          {sub_level.features.map((feature, index) => {
                            return (
                              <List.Item as="li" key={index}>
                                <List.Content>{feature.feature}</List.Content>
                              </List.Item>
                            );
                          })}
                        </List>
                        {sub_level.additional_info && (
                          <h5 className="add-info">
                            {sub_level.additional_info}
                          </h5>
                        )}
                      </div>
                    </Card.Content>
                  )}
                </div>
                <Card className="action-card">
                  <div className="action-card-content">
                    <div className="price">{sub_level.price}€</div>
                    <Button>
                      <Button.Content>S'abbonner</Button.Content>
                    </Button>
                  </div>
                </Card>
              </Card>
            );
          })}
      </Card.Group>
    </div>
  );
};

export default View;
