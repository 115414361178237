import React from 'react';
import { Header, Card, List, Button, Segment } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';

const featuresDict = (features) => {
  let dictionary = Object.assign(
    {},
    ...features.items.map((item) => ({ [item.sub]: item })),
  );
  return dictionary;
};

const checkFeatures = (features) => {};

const ProductTemplate = (props) => {
  const { items, isEditMode } = props;

  return (
    <div className="product-template">
      {items &&
        items.map((item, index) => {
          return (
            <div key={index} className="product-line">
              <ConditionalLink
                key={index}
                href={item.getURL}
                condition={!isEditMode}
              >
                <Header as="h1">
                  {item.title}
                  {item.Description && (
                    <Header.Subheader>{item.Description}</Header.Subheader>
                  )}
                </Header>
              </ConditionalLink>
              <Card.Group className="sub-level-list">
                {item.sub_levels &&
                  item.sub_levels.items.map((sub_level, index) => {
                    return (
                      <Card key={index} className="item-card info">
                        <div className="main-card">
                          <Card.Header>
                            {sub_level.additional_info ? (
                              <h5 className="add-info">
                                {sub_level.additional_info}
                              </h5>
                            ) : (
                              <h5 className="add-info">&nbsp;</h5>
                            )}
                          </Card.Header>
                          {sub_level.features && (
                            <Card.Content>
                              <List as="ul">
                                {sub_level.features.map((feature, index) => {
                                  return (
                                    <List.Item as="li" key={index}>
                                      <List.Content>
                                        {feature.feature}
                                      </List.Content>
                                    </List.Item>
                                  );
                                })}
                              </List>
                            </Card.Content>
                          )}
                        </div>
                        <Card className="action-card">
                          <div className="action-card-content">
                            <h3 className="title">{sub_level.sub}</h3>
                            <div className="price">{sub_level.price}€</div>
                            <Button>
                              <Button.Content>S'abbonner</Button.Content>
                            </Button>
                          </div>
                        </Card>
                      </Card>
                    );
                  })}
              </Card.Group>
            </div>
          );
        })}
    </div>
  );
};

export default ProductTemplate;
