/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment, Icon } from 'semantic-ui-react';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import config from '@plone/volto/registry';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import { readFooter } from '@affinitic/volto/actions';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const companyDataResults = useSelector((state) => state?.footer?.data);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(readFooter());
  }, [dispatch]);

  return (
    <Segment role="contentinfo" vertical padded inverted id="footer">
      <Container>
        <List className="company-data">
          {companyDataResults?.name && (
            <List.Item className="company-name">
              <List.Content>
                <List.Content>
                  <h1>{companyDataResults?.name}</h1>
                </List.Content>
              </List.Content>
            </List.Item>
          )}
          {(companyDataResults?.facebook ||
            companyDataResults?.twitter ||
            companyDataResults?.instagram ||
            companyDataResults?.youtube ||
            companyDataResults?.linkedin ||
            companyDataResults?.tiktok) && (
            <List.Item className="company-social">
              <List.Content>
                {companyDataResults?.facebook && (
                  <a href={companyDataResults?.facebook}>
                    <Icon name="facebook f" />
                  </a>
                )}
                {companyDataResults?.twitter && (
                  <a href={companyDataResults?.twitter}>
                    <Icon name="twitter square" />
                  </a>
                )}
                {companyDataResults?.instagram && (
                  <a href={companyDataResults?.instagram}>
                    <Icon name="instagram" />
                  </a>
                )}
                {companyDataResults?.youtube && (
                  <a href={companyDataResults?.youtube}>
                    <Icon name="youtube" />
                  </a>
                )}
                {companyDataResults?.linkedin && (
                  <a href={companyDataResults?.linkedin}>
                    <Icon name="linkedin" />
                  </a>
                )}
                {/* {companyDataResults?.tiktok && (
                   
                      <a href={companyDataResults?.tiktok}>
                        <Icon name="tiktok" />
                      </a>
                  
                  )} */}
              </List.Content>
            </List.Item>
          )}
          {(companyDataResults?.address ||
            companyDataResults?.zipcode ||
            companyDataResults?.city ||
            companyDataResults?.country ||
            companyDataResults?.email ||
            companyDataResults?.phone) && (
            <List.Item className="company-coordinate">
              <List.Content>
                <Icon name="home" />
                <div>
                  {companyDataResults?.address} -{companyDataResults?.zipcode}{' '}
                  {companyDataResults?.city} ({companyDataResults?.country})
                </div>
                <Icon name="mail" />

                <a href={`mailto:${companyDataResults?.email}`}>
                  {companyDataResults?.email}
                </a>
                <Icon name="phone" />

                <a href={`tel:${companyDataResults?.phone}`}>
                  {companyDataResults?.phone}
                </a>
              </List.Content>
            </List.Item>
          )}
        </List>

        <Segment textAlign="center" basic className="discreet">
          <div className="copyright">
            <FormattedMessage
              id="Copyright {current_year} - Affinitic"
              defaultMessage="Copyright {current_year} - Affinitic"
              values={{
                current_year: new Date().getFullYear(),
              }}
            />
          </div>
        </Segment>
      </Container>
    </Segment>
  );
};

// name
// address
// zipcode
// city
// country
// phone
// email
// website
// logo
// vat
// bank_account
// facebook
// twitter
// linkedin
// youtube
// instagram
// tiktok

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
