import React from 'react';
import { Container, Card } from 'semantic-ui-react';
import {
  Title,
  UniversalLink,
  FormattedRelativeDate,
  DefaultView,
} from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';
import { Plug } from '@plone/volto/components/manage/Pluggable';
import ContextNavigation from '@plone/volto/components/theme/Navigation/ContextNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '@plone/volto/actions';
import jwtDecode from 'jwt-decode';

const ProductView = (props) => {
  const { content } = props;

  if (content.can_access) {
    return (
      <Container className="product-wrapper">
        <article className="container">
          <header>
            <Title title={content.title} />
            {content.description && (
              <p className="documentDescription">{content.description}</p>
            )}
          </header>
          <section className="content-core">
            {content.text?.data && (
              <div
                className="text"
                dangerouslySetInnerHTML={{ __html: content.text.data }}
              />
            )}
            <div className="program-group">
              <Card.Group>
                {content.items.map((item, index) => {
                  return item.release_date ? (
                    <Card className="program-card not-ready" key={index}>
                      <Card.Content>
                        <Card.Header as="h3">
                          Prochain programme disponible{' '}
                          <FormattedRelativeDate
                            locale="fr"
                            date={item.release_date}
                          />
                        </Card.Header>
                      </Card.Content>
                    </Card>
                  ) : (
                    <Card
                      href={item.url}
                      className="program-card waiting"
                      key={index}
                    >
                      <Card.Content>
                        <Card.Header as="h3">{item.title}</Card.Header>
                      </Card.Content>
                    </Card>
                  );
                })}
              </Card.Group>
            </div>
          </section>
        </article>
      </Container>
    );
  } else {
    return (
      <Container className="product-wrapper">
        <article className="container">
          <DefaultView {...props} />
        </article>
      </Container>
    );
  }
};

export default ProductView;
