/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

import {
  ContentFolderView,
  ProductView,
  ProgramView,
  StepView,
  SubStepView,
  VideoPlayerView,
  VideoPlayerEdit,
  FeaturesView,
  FeaturesEdit,
  TestimonialsView,
  TestimonialsEdit,
  ProductTemplate,
  FeatureMenuWidget,
  UserSchemaWidget,
} from './components';

import ListSVG from '@plone/volto/icons/content-listing.svg';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly

  config.settings.maxResponseSize = 15000000000;

  config.views.contentTypesViews.ContentFolder = ContentFolderView;
  config.views.contentTypesViews.Product = ProductView;
  config.views.contentTypesViews.Program = ProgramView;
  config.views.contentTypesViews.Step = StepView;
  config.views.contentTypesViews.SubStep = SubStepView;

  config.settings.showSelfRegistration = true;

  config.widgets.widget.featureMenu = FeatureMenuWidget;
  config.widgets.widget.userSchema = UserSchemaWidget;

  config.blocks.blocksConfig['Features'] = {
    id: 'Features',
    title: 'Features List',
    icon: ListSVG,
    group: 'common',
    view: FeaturesView,
    edit: FeaturesEdit,
    restricted: ({ properties }) => !(properties['@type'] === 'Product'),
    mostUsed: true,
    sidebarTab: 0,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['Testimonials'] = {
    id: 'Testimonials',
    title: 'Testimonials List',
    icon: ListSVG,
    group: 'common',
    view: TestimonialsView,
    edit: TestimonialsEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.video = {
    ...config.blocks.blocksConfig.video,
    title: 'Youtube',
  };

  config.blocks.blocksConfig['VideoPlayer'] = {
    ...config.blocks.blocksConfig['VideoPlayer'],
    restricted: ({ properties }) => !(properties['@type'] === 'SubStep'),
  };

  config.blocks.blocksConfig.listing['variations'] = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'ProductTemplate',
      isDefault: true,
      title: 'Product View',
      template: ProductTemplate,
    },
  ];

  return config;
}
