import React, { Component } from 'react';
import { SidebarPortal, Icon } from '@plone/volto/components';
import { readAsDataURL } from 'promise-file-reader';
import penSVG from '@plone/volto/icons/pen.svg';
import Body from './Body';
import Data from './Data';
import {
  flattenToAppURL,
  getBaseUrl,
  isInternalURL,
} from '@plone/volto/helpers';

export default class Edit extends Component {
  state = {
    uploading: false,
    url: '',
  };

  /**
   * Upload image handler (not used), but useful in case that we want a button
   * not powered by react-dropzone
   * @method onUploadImage
   * @returns {undefined}
   */
  onUploadImage = (e) => {
    e.stopPropagation();
    const file = e.target.image;
    this.setState({
      uploading: true,
    });
    readAsDataURL(file).then((data) => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      this.props.createContent(
        getBaseUrl(this.props.pathname),
        {
          '@type': 'Image',
          title: file.name,
          image: {
            data: fields[3],
            encoding: fields[2],
            'content-type': fields[1],
            filename: file.name,
          },
        },
        this.props.block,
      );
    });
  };

  render() {
    return (
      <>
        Testimonials
        <Body {...this.props} id={this.props.id} isEditMode />
        <SidebarPortal selected={this.props.selected}>
          <Data
            key={this.props.block}
            {...this.props}
            data={this.props.data}
            block={this.props.block}
            onChangeBlock={this.props.onChangeBlock}
          />
        </SidebarPortal>
      </>
    );
  }
}
