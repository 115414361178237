import React, { Component } from 'react';
import { Container, Card, Image } from 'semantic-ui-react';
import {
  Title,
  UniversalLink,
  ListingView,
  FormattedRelativeDate,
} from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';
import { Plug } from '@plone/volto/components/manage/Pluggable';
import ContextNavigation from '@plone/volto/components/theme/Navigation/ContextNavigation';
import taskIcon from './images/task.svg';

const ProgramView = (props) => {
  const { content } = props;
  return (
    <Container className="program-wrapper">
      <article className="container">
        <header>
          <Title title={content.title} />
          {content.description && (
            <p className="documentDescription">{content.description}</p>
          )}
        </header>
        <section className="content-core">
          {content.text?.data && (
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: content.text.data }}
            />
          )}
          <div className="step-group">
            {content.items.map((itemStep, index) => {
              return (
                itemStep.items.length !== 0 && (
                  <div className="step" key={index}>
                    <h3 className="step-title">
                      <UniversalLink href={itemStep.url}>
                        {itemStep.title}
                      </UniversalLink>
                    </h3>
                    <Card.Group className="substep-group">
                      {itemStep.items.map((itemSubStep, index) => {
                        return (
                          <Card
                            className="substep-card little-height waiting"
                            key={index}
                            href={itemSubStep.url}
                          >
                            <Card.Content>
                              <Card.Header>{itemSubStep.title}</Card.Header>
                              {/* <Card className="info task-complete">
                                <Card.Content>
                                  <Image src={taskIcon} />
                                </Card.Content>
                              </Card> */}
                            </Card.Content>
                          </Card>
                        );
                      })}
                    </Card.Group>
                  </div>
                )
              );
            })}
            {content.next_release_date && (
              <div className="step">
                <h3 className="step-title">
                  <FormattedMessage
                    id="Next release"
                    defaultMessage="Next release"
                  />
                </h3>
                <div className="step-unrelease">
                  <Card className="info">
                    <Card.Content>
                      <Card.Header>
                        Disponible{' '}
                        <FormattedRelativeDate
                          locale="fr"
                          date={content.next_release_date}
                        />
                      </Card.Header>
                    </Card.Content>
                  </Card>
                  <Card.Group className="substep-group">
                    {[...Array(4)].map((_, i) => {
                      return (
                        <Card className="substep-card little-height scafolding"></Card>
                      );
                    })}
                  </Card.Group>
                </div>
              </div>
            )}
          </div>
        </section>
      </article>
    </Container>
  );
};

export default ProgramView;
