import React, { Component } from 'react';
import { Card, Header } from 'semantic-ui-react';
import cx from 'classnames';

const Body = (props) => {
  const { data, id, isEditMode, intl, properties } = props;

  return (
    <div className="testimonials">
      <h2>{data.title}</h2>
      <Card.Group className="testimonials-grid">
        {data.testimonials &&
          data.testimonials.map((testimonial, index) => (
            <Card
              key={index}
              className={cx(
                'testimonial',
                `layout-${testimonial.layout}`,
                `color-${testimonial.color}`,
              )}
            >
              {isEditMode && (
                <div className="edit-help">{`TESTIMONIAL #${index + 1}`}</div>
              )}
              <Card.Content>
                {testimonial.quote && (
                  <h5
                    dangerouslySetInnerHTML={{ __html: testimonial.quote.data }}
                  />
                )}
                <div className="testimonial-author">
                  <div className="testimonial-author-info">
                    <div className="testimonial-author-name">
                      {testimonial.author}
                    </div>
                    <div className="testimonial-author-function">
                      {testimonial.function}
                    </div>
                  </div>
                  <div className="testimonial-author-image">
                    {/* <img src={testimonial.image.data} alt="" /> */}
                  </div>
                </div>
              </Card.Content>
            </Card>
          ))}
      </Card.Group>
    </div>
  );
};

export default Body;
