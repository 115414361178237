import React, { Component } from 'react';
import { SidebarPortal, Icon } from '@plone/volto/components';
import penSVG from '@plone/volto/icons/pen.svg';
import Body from './Body';
import Data from './Data';
import ContentsPropertiesModal from '../../../../omelette/src/components/manage/Contents/ContentsPropertiesModal';

export default class Edit extends Component {
  render() {
    return (
      <>
        <Body {...this.props} id={this.props.id} isEditMode />
      </>
    );
  }
}
