import React, { Component } from 'react';
import { Container, Card } from 'semantic-ui-react';
import { Title, UniversalLink } from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';
import { Plug } from '@plone/volto/components/manage/Pluggable';
import ContextNavigation from '@plone/volto/components/theme/Navigation/ContextNavigation';

const StepView = (props) => {
  const { content } = props;

  return (
    <Container className="step-wrapper">
      <article className="container">
        <header>
          <Title title={content.title} />
          {content.description && (
            <p className="documentDescription">{content.description}</p>
          )}
        </header>
        <section className="content-core">
          {content.text?.data && (
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: content.text.data }}
            />
          )}

          <div className="program-group">
            <Card.Group className="substep-group">
              {content.items.map((item, index) => {
                return (
                  <Card
                    href={item.url}
                    className="program-card waiting little-height"
                    key={index}
                  >
                    <Card.Content>
                      <Card.Header>{item.title}</Card.Header>
                      <Card.Meta>{item.description}</Card.Meta>
                    </Card.Content>
                  </Card>
                );
              })}
            </Card.Group>
          </div>
        </section>
      </article>
    </Container>
  );
};

export default StepView;
