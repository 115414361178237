const testimonialSchema = (props) => {
  return {
    title: 'Testimonial',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['author', 'function', 'color', 'image', 'layout', 'quote'],
      },
    ],
    properties: {
      author: {
        title: 'Author',
        description: 'The name of the person who wrote the testimonial',
        type: 'string',
      },
      function: {
        title: 'Function',
        description: 'Function of the person who wrote the testimonial',
        type: 'string',
      },
      image: {
        title: 'Image',
        description: 'The image of the person who wrote the testimonial',
        widget: 'file',
      },
      layout: {
        title: 'Layout',
        description: 'The layout of the testimonial',
        choices: [
          ['vertical', 'Vertical'],
          ['horizontal', 'Horizontal'],
          ['min', 'Mini'],
        ],
      },
      quote: {
        title: 'Quote',
        description: 'The quote of the person who wrote the testimonial',
        widget: 'richtext',
      },
      color: {
        title: 'Color',
        description: 'The color of the testimonial',
        default: 'primary',
        choices: [
          ['primary', 'Primary'],
          ['secondary', 'Secondary'],
          ['tertiary', 'Tertiary'],
        ],
      },
    },
    required: [],
  };
};

export const Schema = (props) => {
  return {
    title: 'Testimonials',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'testimonials'],
      },
    ],

    properties: {
      title: { title: 'Title', type: 'string' },
      testimonials: {
        title: 'Testimonials',
        widget: 'object_list',
        schema: testimonialSchema,
      },
    },
    required: [],
  };
};
