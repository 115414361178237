import React, { Component } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const UserSchemaWidget = (props) => {
  const { value } = props;

  function onChange(id, value) {
    props.onChange(id, { items: value });
  }

  function schema(props) {
    return {
      title: 'User Schema',

      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['userid', 'begin_date'],
        },
      ],
      properties: {
        userid: {
          title: 'User ID',
          vocabulary: { '@id': 'plone.app.vocabularies.Users' },
        },
        begin_date: {
          title: 'Begin Date',
          widget: 'datetime',
        },
      },
      required: ['userid, begin_date'],
    };
  }

  return (
    <ObjectListWidget
      {...props}
      value={value?.items}
      schema={schema}
      onChange={onChange}
    />
  );
};

export default UserSchemaWidget;
