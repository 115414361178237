import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';
import { Title, UniversalLink } from '@plone/volto/components';
import { Plug } from '@plone/volto/components/manage/Pluggable';
import ContextNavigation from '@plone/volto/components/theme/Navigation/ContextNavigation';
import { DefaultView } from '@plone/volto/components';

const SubStepView = (props) => {
  const { content } = props;

  return (
    <Container className="substep-wrapper">
      <DefaultView content={content} />
    </Container>
  );
};

export default SubStepView;
