import React, { Component } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const FeatureMenuWidget = (props) => {
  const { value } = props;
  const intl = useIntl();

  function onChange(id, value) {
    props.onChange(id, { items: value });
  }

  function featureSchema(props) {
    return {
      title: 'Feature List',
      addMessage: 'Add Feature',
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['feature'],
        },
      ],
      properties: {
        feature: {
          title: 'Feature',
        },
      },
      required: ['feature'],
    };
  }

  function schema(props) {
    return {
      title: 'Feature Menu',
      addMessage: 'Add Sub Level',
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: [
            'sub',
            'description',
            'price',
            'additional_info',
            'features',
          ],
        },
      ],

      properties: {
        sub: {
          title: 'Sub Level',
          vocabulary: { '@id': 'elearning.sub_levels' },
        },
        features: {
          title: 'Features',
          widget: 'object_list',
          schema: featureSchema,
        },
        additional_info: {
          title: 'Additional Info',
          default: '',
        },
        price: {
          title: 'Price',
          type: 'number',
        },
        description: {
          title: 'Description',
          widget: 'richtext',
        },
      },
      required: ['sub', 'price', 'features'],
    };
  }

  return (
    <ObjectListWidget
      {...props}
      value={value?.items}
      schema={schema}
      onChange={onChange}
    />
  );
};

export default FeatureMenuWidget;
